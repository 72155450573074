import React from "react";
import Helmet from "react-helmet";
import { Link } from "gatsby";
import SEO from "../../components/Seo";
import Layout from "../../components/Layout";
import MainContent from "../../components/MainContent";
import Breadcrumb from "../../components/Breadcrumb";
import InfoBox from "../../components/InfoBox";
import QuoteBox from "../../components/QuoteBox";
import CtaPrimary from "../../components/CtaPrimary";
import { currentYear } from "../../components/Helpers";
import ImgScreenshot from "../../components/ImgScreenshot.js";
import ImgContainerFixed from "../../components/ImgContainerFixed";
import H from "../../components/Headline";


const breadCrumbLevels = {
  Home: "/",
  "Analytics Consulting": "/en/analytics-consulting",
  "GTM Tutorial": "/en/google-tag-manager-setup"
};

// hreflang data
const alternateLangs = [
  {
    hreflang: "en",
    href: "/en/google-tag-manager-setup"
  },
  {
    hreflang: "de",
    href: "/de/google-tag-manager-einrichten"
  },
  {
    hreflang: "x-default",
    href: "/en/google-tag-manager-setup"
  }
];

// Schema
// todo Schema WebSite WebPage Article ImageObject FAQPage Person HowTo
// todo add video tracking, scroll tracking and form tracking as references to different guides

const FAQs = `
{
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": [
      {
        "@type": "Question",
        "name": "Do I need Google Tag Manager?",
        "acceptedAnswer": {
        "@type": "Answer",
        "text": "Yes, because your website most likely wants to run Google Analytics and other third-party script tags. Setting all that up is a lot easier and faster with Google Tag Manager. Plus your site <a href='https://marketingland.com/audit-of-online-retailer-sites-shows-tag-management-systems-improve-load-times-reduce-errors-62173'>loads a bit faster</a> too."
        }
      },
      {
      "@type": "Question",
      "name": "When should I use Google Tag Manager?",
      "acceptedAnswer": {
      "@type": "Answer",
      "text": "Already if you only want to run Google Analytics you should use Google Tag Manager. Setting up <a href='https://bluerivermountains.com/en/event-tracking'>event tracking</a> and other configurations is a lot faster and easier with GTM. Plus, there are many tutorials and guides online that explain how to e.g. <a href='http://bluerivermountains.com/en/google-analytics-setup'>set up Google Analytics</a>."
        }
      },
      {
        "@type": "Question",
        "name": "How do I use Google Tag Manager with Google Analytics?",
        "acceptedAnswer": {
        "@type": "Answer",
        "text": "Don’t put the Google Analytics tag into the source code of your site. Only add the Google Tag Manager tag and then implement and <a href='https://bluerivermountains.com/en/google-analytics-setup'>set up Google Analytics</a> through Google Tag Manager. Any custom configurations like <a href='https://bluerivermountains.com/en/event-tracking'>event tracking</a> or adding other script tags, you do that in GTM."
        }
     },
     {
       "@type": "Question",
       "name": "What is the difference between Google Analytics and Google Tag Manager?",
       "acceptedAnswer": {
       "@type": "Answer",
       "text": "Google Analytics is the library that collects data about visits to your website. Google Tag Manager on the other hand is a library running on your site to implement other libraries or tools like Google Analytics. Because many of those tools have extra JavaScript snippets to send data to them, you set them all up in GTM."
       }
    },
    {
      "@type": "Question",
      "name": "Where do I put the Google Tag Manager code?",
      "acceptedAnswer": {
      "@type": "Answer",
      "text": "The first part of the code goes as high as possible into the <head> section. I recommend to implement it within the <head> but after any <style> or <script> tags that are vital to render the page - because we don’t want to delay them. The second part of the GTM code snippet is to enable a basic functionality on sites with JavaScript turned off. It goes as high as possible into the <body> element. The logic behind the positioning of both tags is to ensure the early loading of GTM. It enables you to interfere and control parts of the page load process as early as possible."
      }
   },
   {
     "@type": "Question",
     "name": "Does Google Tag Manager include Google Analytics?",
     "acceptedAnswer": {
     "@type": "Answer",
     "text": "No, but Google Tag Manager enables you to implement Google Analytics in seconds with just a few clicks. The only thing you need is your Google Analytics tracking ID. Generally though, you don’t need to use Google Analytics with Google Tag Manager. They are independent of each other."
     }
  }
  ]
}
`;

const GTMsetup = props => (
  <Layout location={props.location} alternateLangs={alternateLangs}>

    <React.Fragment>
      <SEO
        title={`Install Google Tag Manager Tutorial: Setup Guide ${currentYear}`}
        description="Learn how to use GTM to set up Google Analytics, event tracking, remarketing tags and a data layer, plus best practices for tracking downloads and external links."
        lang="en"
        canonical="/en/google-tag-manager-setup"
        image="google-tag-manager-tutorial-hero.png"
        alternateLangs={alternateLangs}
      />
      <MainContent article>
        <ImgScreenshot
          src="gtm-setup/google-tag-manager-tutorial-hero.png"
          alt="user interface of Google Tag Manager"
        />
        <Breadcrumb breadCrumbLevels={breadCrumbLevels} />
        <H as="h1">Google Tag Manager Tutorial</H>

        <p>As a <Link to="/en/google-tag-manager-consultant">Google Tag Manager consultant</Link>, I've set up GTM on <b>100+ client websites</b>. This Google Tag Manager tutorial is where I teach you the process I've refined over the years, step by step, with examples and videos for you to learn.</p>
        <p>Further down, you can <Link to="/en/google-tag-manager-setup#download-gtm-config-container-file">download a GTM setup configuration file</Link> with all of the following best practices to import into your container.</p>
        <p>If you can't wait, jump right into the <Link to="/en/google-tag-manager-setup#install-google-tag-manager-on-your-website">installation</Link> tutorial or learn <Link to="/en/google-tag-manager-setup#how-to-set-up-google-tag-manager">how to set up Google Tag Manager</Link>. But if you are a <b>beginner</b> it is important to first understand <em>how</em> to use a <Link to="/en/tag-management">tag management system</Link> together with other tools.</p>
        <p>So keep on reading below first.</p>
        <H as="h2">How to use Google Tag Manager?</H>
        <p>I assume you already know <Link to="/en/what-is-google-tag-manager">what Google Tag Manager is</Link>. So lets talk about how GTM works and how to use it.</p>
        <p>Ideally, you only want to have <b>one</b> 3rd-party tag in the source code of your website or web app.</p>
        <QuoteBox
          quote={`The only 3rd-party tag on your website should be the Google Tag Manager code snippet.`}
        />
        <p>All other tags are then implemented through the tag manager itself. Other marketing and tracking tags are e.g. Google Analytics (GA), Facebook, Twitter, Linkedin, AdWords, DoubleClick and god knows what.</p>
        <p>The primary reason are the <Link to="/en/google-tag-manager-benefits">advantages of Google Tag Manager</Link>:</p>
        <ul>
          <li><b>easier & faster</b> implementation of marketing tags</li>
          <li>scalability on every page and across multiple domains</li>
          <li><b>built-in triggers</b> for form submissions, scroll tracking & video tracking</li>
          <li>manage users with multiple gtm accounts</li>
          <li>a bit <a rel="noopener" target="_blank" href="https://marketingland.com/audit-of-online-retailer-sites-shows-tag-management-systems-improve-load-times-reduce-errors-62173">faster site load speed</a></li>
        </ul><br />
        <p>Due to these advantages, already <a target="_blank" href="https://w3techs.com/technologies/overview/tag_manager">30% of all websites on the internet use a tag manager</a>. And among them Google Tag Manager has a market share of <a target="_blank" rel="noopener" href="https://trends.builtwith.com/analytics/tag-management/traffic/Entire-Internet">94%</a>.</p>
        <p>So, unless you have a solid reason not to add a tag to GTM, as a general rule of thumb, <b>add all tags to the GTM
          container</b>.</p>

        <QuoteBox
          quote={`Use GTM like a connecting layer between your website and 3rd-party tags.`}
        />
        <p>Use GTM like a <b>middle-layer</b> between your website and 3rd-party services. Without it, your site and 3rd party tags are not in direct connection. Those services are mostly JavaScript libraries for marketing or tracking tools that are implemented with a tag. But any other tags apply as well.</p>
        <p>The only exception to the rule applies when you do conversion optimization with split-testing tools.</p>
        <p>Because during conversion rate optimization, A/B tests are going to load different variants of a page. So the visitor may see how the content is re-rendered for a split-second.</p>
        <p>To avoid CSS flicker and ensure that variant tests load fast, a split-testing tag may also go directly into the source code.</p>
        <p>Now that we have this out of the way, let’s look at the implementation.</p>
        <H as="h2">Install Google Tag Manager on your website</H>
        <p>Let's start the Google Tag Manager tutorial by showing you where to get the Google Tag Manager code snippet and then where to install it on the website. You can log in just by using your usual Google account.</p>
        <ol>
          <li><H as="h3">Create a Google Tag Manager account</H>
            To install GTM, you first have to go to the <a rel="noopener" target="_blank"
              href="https://tagmanager.google.com/">official website</a> and create a new Google Tag Manager account.<br />
            <ImgScreenshot
              src="google-analytics-setup/google-tag-manager-create-account.png"
              alt="Google Tag Manager account creation"
              caption="First, create a Google Tag Manager account, and choose a container name, like your website name and then get the code snippet."
              className="article-img"
            />
          </li>
          <li><H as="h3">Create a web-property</H>
            Select the <b>Web</b> property to get a code for a website or web app.<br />
            <ImgScreenshot
              src="google-analytics-setup/google-analytics-setup-via-google-tag-manager-container-creation.png"
              alt="GTM container name and web property selection"
              className="article-img"
              caption="There are multiple types of containers available in a GTM account. For websites, choose web. Note that there are other tag manager container types for AMP pages, iOS and Android too."
            />
          </li>
          <li><H as="h3">Implement the Google Tag Manager code</H><ul>
            Afterwards, you will be shown the Google Tag Manager code to implement on your website.<br />
            <ImgScreenshot
              src="google-analytics-setup/google-tag-manager-code-snippet.png"
              alt="Google Tag Manager code snippet"
              caption="This is the Google Tag Manager container tag. It has two parts. The instructions how to implement the script tags are written above each part."
              className="article-img"
            />
            <li>Take the <b>first part</b> of the <Link to="/en/wiki-analytics/container-tag">container tag</Link> and put it as high as possible in the <b>head</b> tag on every page of your website. This ensures that you can fire tags early during page loads.</li>
            <li>The <b>second part</b> is an iframe to run in browsers that have JavaScript disabled. Install the tag as high as possible in the <b>body</b> tag on each page of your website.<br />
              <ImgScreenshot
                src="data-layer/positionierung-data-layer-gtm.png"
                alt="the dataLayer is positioned before the TMS tag in the source code"
                caption={`The first tag in the <head> is a data layer. Don't worry if you don't know yet what that is (first arrow). Next is the first part of the GTM tag (second arrow). Finally, the other part of the GTM tag is implemented high up in the <body> element.  or JavaScript code can be implemented in between, but a data layer is always implemented before the GTM tag and the <noscript> GTM tag is always last.`}
                className="article-img"
              />
            </li>
          </ul>
          </li>

        </ol>
        <p>This is the common method to implement GTM.</p>
        <p>Do you use a popular content management system? If yes, you can also use a <b>plugin</b> that takes care of the Google Tag Manager installation.</p>
        <p>That said:</p>
        <InfoBox
          type="info"
          headline="If your CMS also offers you a plugin to install other tags"
          h="false"
          dots="true"
          tweetcopy="If your CMS also offers you a plugin to install other tags, don't use yet another plugin to install Google Analytics, Facebook or Google Ads. Instead, use GTM to install those tags."
        >
          <p>Don't use yet another plugin to install Google Analytics, Facebook or Google Ads.</p>
          <p>Instead, <b>use GTM to install those tags</b>.</p><br />
          <ol>
            <li>It will result in a faster page load speed</li>
            <li>It gives you more options to configure the tag</li>
          </ol>
        </InfoBox>
        <p>The GTM user interface also receives updates with new features regularly, so you are almost always better off implementing other marketing tags directly with it than with another integration.</p><p>Plus, the gains in load time are good for your <Link to="/en/wiki-analytics/bounce-rate">bounce rate</Link> and help SEO.</p>
        <H as="h3">Use a plugin to implement GTM</H>
        <p>Below a list of the most common content management systems and their plugins to install Google Tag Manager.</p>
        <H as="h4">WordPress</H>
        <p>There are two WordPress plugins to implement GTM that I would use. <b>First</b>, there is the classic option called <a rel="noopener" target="_blank" href="https://wordpress.org/plugins/duracelltomi-google-tag-manager/">Google Tag Manager
          for WordPress</a>.<br />
          The <b>second</b> option is <a rel="noopener" target="_blank" href="https://wordpress.org/plugins/google-site-kit/">Site
            Kit by Google</a>. It primarily allows you to add a dashboard to your Worpress backend showing information from your Google Analytics account and Google Search Console data - which is pretty sweet. And it also allows you to install GTM.</p>
        <H as="h4">Shopify</H>
        <p>For Shopify, there is a <b>free</b> plugin for GTM installation creatively called <em><a rel="noopener" target="_blank"
          href="https://apps.shopify.com/trafficguard?surface_detail=google+tag+manager&amp;surface_inter_position=1&amp;surface_intra_position=6&amp;surface_type=search">Google
          Tag Manager Installer</a></em>.</p>
        <H as="h4">Squarespace</H>
        <p>For Squarespace, there is no GTM extension or plugin. But you can add the GTM tag manually, by visiting <b>sidebar</b> &gt; <b>settings</b> &gt; <b>advanced</b> &gt; <b>code injection</b>.</p>

        <ImgScreenshot
          src="gtm-setup/google-tag-manager-installation-in-squarespace.png"
          alt="Squarespace navigation menu for code injection"
          caption={`In Squarespace you can implement GTM under Settings > Advanced > Code Injection`}
          className="article-img"
        />

        <p>Next, you paste the GTM tag into the form fields like this:</p>


        <ImgScreenshot
          src="gtm-setup/gtm-code-injection-in-squarespace.png"
          alt="Inject GTM code snippets into Squarespace"
          caption={`Put the first part of the GTM code in the header field. The Second part goes into the footer field.`}
          className="article-img"
        />

        <H as="h4">Wix</H>
        <p>Visit the main menu for your Wix website on the left sidebar. From there visit <b>Marketing &amp; SEO</b> and then
          click on <b>Marketing Integrations</b> further down in the sidebar.<br />
          Then you will find multiple Wix integrations for Google Analytics, the Facebook pixel and also one Wix extension to implement Google Tag Manager.</p>

        <ImgScreenshot
          src="gtm-setup/google-tag-manager-setup-in-wix.png"
          alt="Wix Marketing Integration for Google Tag Manager"
          caption={`In Wix use the marketing integration for Google Tag Manager.`}
          className="article-img"
        />
        <p>Click on connect and get Google Tag Manager installed.</p>










        <H as="h2">How to check if GTM is working?</H>
        <InfoBox
          type="info"
          headline="When you first log in to GTM"
          h="false"
          dots="true"
          tweetcopy="When you first log in to GTM...Go to the submit button and publish an empty container. Otherwise, once you test if GTM works, the script will return a 400 response error and you will spend hours debugging why. 😭"
        >
          <p>Go to the submit button and publish an <b>empty container</b>.</p>
          <p>Otherwise, once you test if GTM works, the script will return a <b>400 response error</b> and you will spend hours debugging why. 😭 <br /><br />It's a classic 😉</p>
        </InfoBox>
        <p>After you implemented the GTM script and <b>published a container</b> version (important), you can test if Google Tag Manager is working by doing any of these checks:</p>
        <ol>
          <li><H as="h3">Preview and debug mode</H>Log into your GTM account and click on <b>preview</b>. Then, open a new tab in the browser and visit your website. The GTM debugger window should pop open on the bottom of the window if GTM works correctly.<br />
            <ImgScreenshot
              src="event-tracking/google-tag-manager-event-trigger-preview.png"
              alt="Activate GTM debugger mode"
              caption={`Activate the GTM debugger mode to check if GTM is working correctly.`}
              className="article-img"
            />

          </li>
          <li><H as="h3">Chrome Developer Tools</H><b>Open Chrome Developer Tools</b> with a right-click on any page of your site and select <em>inspect</em> (Alternatively F12 on Windows and Shift+CTRL+J on Mac).<br />
            Then you go to the <b>network</b> tab and <b>simultaneously reload the web page</b> (F5 on Windows and CMD+Shift+R
            on Mac). The network tab will fill with all network requests necessary to load the page.<br />
            In the filter field in the top-left, type <em>gtm.js</em> to find the request for your JavaScript code and verify it has
            a <b>status code of 200</b>.<br /><br />
            Let me show you:<br /><br />
            <video

              loading="lazy"
              title={`Check if Google Tag Manager is working`}
              autoplay
              muted
              playsinline
              loop
              controls
              css="max-width:100%;border:3px solid lightgrey;"
            >
              <source src="/video/check-if-gtm-is-working.mp4" type="video/mp4" />
            </video>
            <br />
            <b>If you don’t have a 200 status code, maybe you forgot to submit and publish a container first in GTM?</b></li>
          <li><H as="h3">Google Tag Assistant</H>Install the <a rel="noopener" target="_blank" href="https://chrome.google.com/webstore/detail/tag-assistant-by-google/kejbdjndbnbjgmefkgdddjlbokphdefk">Google Tag Assistant</a> Chrome extension and start it on your site. It should call out a GTM container ID.<br />

            <ImgContainerFixed width="452px">
              <ImgScreenshot
                src="gtm-setup/gtm-validation-with-tag-inspector.png"
                alt="Google Tag inspector validates GTM is loading correctly"
                caption={`You can also use the Chrome Extension Google Tag Assistant to ensure Google Tag Manager is working correctly.`}
                className="article-img"
              />
            </ImgContainerFixed>
          </li>
        </ol>
        <H as="h2">How to set up Google Tag Manager?</H>
        <p>When setting up Google Tag Manager you can make many advanced configurations. So <b><em>how</em></b> you set up GTM, depends
          on what other tools you plan to use in your <Link to="/en/tag-management">tag management system</Link>.</p>
        <p>That's why I brought together all relevant tutorials that cover whatever you could possibly want to set up in your GTM account - with
          examples. Simply follow this Google Tag Manager guide and thereby create a solid tag management foundation for your site.</p>
        <p>Only the tutorial on implementing a data layer requires coding skills or potentially web developers.</p>
        <p><b>Note</b>: In this Google Tag Manager tutorial, we will use GTM by <b>manually</b> setting up new tags and triggers for each event. The approach is not super scalable, but it is fast enough and easy, while meeting most tracking ambitions and still being applicable to other advanced setups.</p>
        <p>Larger websites and e-commerce stores require a <b>scalable tag management solution</b>. Therefore a <Link to="/en/data-layer">data layer</Link> is implemented as the central piece to track events. With such a setup, you can use event handlers instead of setting up tags, triggers and variables for each event.</p>
        <ol>
          <li>
            <H as="h3">Set up Google Analytics tracking</H>
            <p>This is the first step for everybody. Learn in this guide how to implement solid Google Analytics tracking, with Goals, Funnels, and your own visits
              excluded from the traffic. Plus more best practices.</p>

            <Link to="/en/google-analytics-setup"><CtaPrimary color="purple" arrow="true">Set up Google Analytics</CtaPrimary></Link>

          </li>
          <li>
            <H as="h3">Set up event tracking</H>
            <p>Once the fundamental tracking implementation is running as it should, you will also want to learn tracking user
              engagement. How often, for example, does a visitor send form submissions and click on a submit button or another HTML element? My <Link to="/en/event-tracking">event tracking</Link> guide explains exactly that for a <b>button click</b> and
              you can use the same method for any other click tracking.</p>
            <Link to="/en/event-tracking"><CtaPrimary color="purple" arrow="true">Set up event tracking</CtaPrimary></Link>
          </li>
          <li>
            <H as="h3">Add remarketing tags</H>
            <p>The most common use-case for GTM <em>after</em> installing GA is adding remarketing tags to a website. After all, they make the majority of 3rd-party marketing tags and tracking codes that clutter the code base of our sites.<br />
              Hence we implement them through our GTM account to keep the code base clean from marketing and analytics tags while taking advantage of the <Link to="/en/google-tag-manager-benefits">benefits of Google Tag Manager</Link>.</p>
            <p>Let’s learn how to add the most common remarketing tags in the digital marketing space, the <b>Facebook pixel</b>, and the <b>Google Ads
              remarketing tag</b>.</p>
            <H as="h4">Add Facebook pixel</H>
            <ul>
              <li>
                First, you will need your <b>Facebook pixel ID</b>. Visit Facebook’s <a rel="noopener" target="_blank"
                  href="https://www.facebook.com/events_manager2/list/pixel/">Events Manager</a> and click the <b>green plus symbol</b> to create the Facebook pixel. Afterwards, your pixel ID will be listed on
                the screen.
                <ImgScreenshot
                  src="gtm-setup/get-fb-pixel.png"
                  alt="Event Manager in Facebook shows the pixel ID"
                  caption={`Find your Facebook pixel ID in the Event Manager.`}
                  className="article-img"
                />
              </li>
              <li>
                Then via Google Tag Manager, <b>create a new tag</b>, call it for example <em>Facebook - Page
                  view</em> and visit the gallery for<b>tag templates</b>.
              </li>
              <li>
                Search for <em>Facebook</em> and select the Facebook Pixel.<br />

                <ImgScreenshot
                  src="gtm-setup/fb-pixel-template-gallery-gtm.png"
                  alt="GTM's template gallery shows Facebook pixel tag"
                  caption={`Implement the Facebook pixel from GTM's tag templates.`}
                  className="article-img"
                />
              </li>
              <li>
                Add your <b>Facebook Pixel ID</b> and click <b>save</b>.
              </li>
              <li>
                Set the tag to fire on <b>all pages</b>.
                <br /><br />
                <video

                  loading="lazy"
                  autoplay
                  muted
                  playsinline
                  loop
                  controls
                  title={`Install Facebook pixel in Google Tag Manager`}
                  css="max-width:100%;border:3px solid lightgrey;"
                >
                  <source src="/video/add-facebook-pixel-with-gtm.mp4" type="video/mp4" />
                </video>
              </li>
              <li>
                Afterwards, click <b>submit</b> in the top right corner to push the tag live.
              </li>
            </ul>
            <H as="h4">Add Google Ads remarketing</H>
            <ul>
              <li><b>First</b>, get your <b>Google Ads conversion ID</b> for your audience from <b>Shared Library &gt; Audiences</b>. The user interface changed recently, but look for <b>tag details</b> or <b>setup tag</b> to find the below information.
                <br />
                <ImgContainerFixed width="432px">
                  <ImgScreenshot
                    src="gtm-setup/google-ads-conversion-id.png"
                    alt="Code snippets for Google conversion ID and conversion label"
                    caption={`Take your conversion ID and conversion label from the tag details in your audience.`}
                    className="article-img"
                  />
                </ImgContainerFixed>
              </li>
              <li>Then in GTM, go to the <b>tags</b> section and click on <b>new</b> to add our new marketing tag.</li>
              <li>Give it a name like <em>Google Ads - Page view</em>.</li>
              <li>Choose as type of variable <b>Google Ads Remarketing</b>.</li>
              <li>Set your <b>conversion ID</b> and optionally the <b>conversion label</b>. Then let the tag fire on <b>all pages</b> and click <b>save</b>.<br /><br />Let me show you in this video:
                <video

                  loading="lazy"
                  autoplay
                  muted
                  playsinline
                  loop
                  controls
                  title={`Install Google Ads page view with GTM`}
                  css="max-width:100%;border:3px solid lightgrey;"
                >
                  <source src="/video/google-ads-page-view.mp4" type="video/mp4" />
                </video>
              </li>
              <li>Click <b>submit</b> in the top right corner to push the marketing tag live.</li>
            </ul>
            {/* Link -&gt; how to setup conversion google tag manager<br /> */}
            {/* [//]: # (### Implement form tracking)<br /> */}
            {/* [//]: # (Link -&gt; form tracking) */}
            {/* implement scroll tracking */}
            {/* implement video tracking */}
          </li>
          <li>
            <H as="h3">Implement a data layer</H>
            <p>You will want to implement a data layer if you set up tags on a regular basis and it takes too long and is simply too labor-intensive.</p>
            <p>Another benefit is that you can use the information from your database for firing triggers or send it as event data. Other external data sources can also be integrated. Webites that that need ecommerce tracking typically fall into this category.</p>
            <p>My article about the data layer explains the implementation, data layer variables and how to configure custom tracking in a scalable way, which is a typical use-case for <b>large ecommerce stores</b> that need enhanced ecommerce tracking.
            </p>

            <Link to="/en/data-layer"><CtaPrimary color="purple" arrow="true">Implement data layer</CtaPrimary></Link>
          </li>
        </ol>
        <H as="h2">Best practices for every GTM setup</H>
        <p>Each time I set up Google Tag Manager, the setup comes with a few configurations that I add every time. These
          best practices are applicable and helpful for most businesses and shouldn't be missing in any GTM tutorial. See the list below and pick the ones useful to you.</p>
        <p>Further down, you can <Link to="/en/google-tag-manager-setup#download-gtm-config-container-file">download a GTM setup configuration</Link> with all these best practices to import into your own container.</p>
        <H as="h3">Track outbound links</H>
        <p>To track outbound link clicks means to track any clicks on external links that lead from your website to other websites.
          Tracking external links is a best practice that let's you know to which websites you send visits and helps you verify the interest of your visitors.<br />To implement external link tracking, there are <b>three steps</b>:</p>
        <ol>
          <li>
            <H as="h4">Create a custom event variable in GTM</H>
            <ul>
              <li>Visit <b>user-defined variables</b> from the GTM overview and click on <b>new &gt; auto-event variable</b>.</li>
              <li>It is supposed to evaluate the link URL of a clicked element and return <code>true</code> if the <code>href</code> attribute holds an outbound link - or <code>false</code> if the link is internal.</li>
              <li>Give the new variable a name like <em>Linkisoutbound</em> and select the <em>variable type</em> to be <b>Element URL</b> and the <em>Component Type</em> <b>is outbound</b>. Then click <b>save</b>.<br />

                <ImgScreenshot
                  src="gtm-setup/auto-event-variable-for-outbound-links.png"
                  alt="Settings for outbound link auto-event variable"
                  caption="The auto-event variable in Google Tag Manager is going to hold a value of TRUE when the address of the clicked link is outbound and pointing to an external page."
                  className="article-img"
                />

              </li>
            </ul>
          </li>
          <li>
            <H as="h4">Create a new trigger for outbound links</H>
            <ul>
              <li>In the side navigation under triggers, click new and create a new trigger in GTM.</li>
              <li>Select the <em>trigger type</em> to be <b>just links</b> and name it e.g. <em>Event - Outbound
                Link</em>.</li>
              <li>Set the trigger to <em>fire on some link clicks</em> and select your new auto-event variable <b>Linkisoutbound</b> to equal <code>true</code>:<br />


                <ImgScreenshot
                  src="gtm-setup/outbound-links-trigger-config.png"
                  alt="Trigger configuration for outbound link tracking"
                  caption={`Trigger settings for tracking outbound links.`}
                  className="article-img"
                />

              </li>

            </ul>
          </li>
          <li>
            <H as="h4">Create a tag for the GA event</H>
            <ul>
              <li>Visit <b>variables</b> and click <b>configure</b> for the <em>built-in variables</em>. Then activate the <b>click URL</b> variable. Close again and go to the tags section.</li>
              <li>Create a new <b>Google Analytics: Universal Analytics</b> tag named <em>Event - Outbound link</em> with the <em>track type</em> set to <b>event</b>.</li>
              <li>For the <b>category</b> just add <code>Outbound link click</code>, <b>action</b> is <code>click</code> and <b>label</b> shall be referencing our newly activated GTM variable. To do that, we use double curly
                braces <code>{`{{ Click URL }}`}</code>.</li>
              <li>The event <b>value</b> can be set to <code>0</code>, unless you can assign a nominal value for an
                external link (e.g. for affiliate links).</li>
              <li>The <b>non-interaction hit</b> setting stays set to <code>false</code>, because an outbound click is
                indeed a user interaction.</li>
              <li>The <b>Google Analytics settings variable</b> should be the same user-defined variable as in your tag for page views.<br />


                <ImgScreenshot
                  src="gtm-setup/google-analytics-event-tag-external-link-clicks.png"
                  alt="Settings for the Google Analytics event tag to track outbound links"
                  caption={`Settings for the event tag to track outbound links.`}
                  className="article-img"
                />

              </li>

              <li>As the last step, scroll down to the <em>triggering</em> section and <b>select the newly created trigger</b> <em>Event - Outbound Link</em>. Then hit <b>save</b> and <b>submit</b> your container changes from the
                main overview.</li>
            </ul>
          </li>
        </ol>
        <H as="h3">Track clicks on email adresses</H>
        <p>Clicks on emails are a helpful metric that tends to correlate with phone calls or visits to a physical shop.<br />To set up Google Analytics tracking for email clicks follow the steps in below tutorial:</p>
        <ol>
          <li>
            <H as="h4">Add a new trigger for email address clicks</H>
            <ul>
              <li>Activate the <b>click URL</b> built-in variable. You can skip this step if you’ve already done this in
                the previous set up. Otherwise: Go to the <b>variables</b> section and <b>configure</b> the <em>built-in
                  variables</em> to add the <b>click URL</b> variable. Afterwards, close the panel and go to the
                triggers section.</li>
              <li>Under <b>triggers</b>, click <em>new</em> and create a new trigger named e.g. <em>Event - Mail
                click</em>, set the type to <b>click - just links</b> and let it only trigger on <em>some link
                  clicks</em>.</li>
              <li>In the condition for those link clicks, set the dropdowns to be <b>Click URL</b>, then <b>contains</b> and then just add the string <code>mailto:</code><br /></li>

              <ImgScreenshot
                src="gtm-setup/email-click-trigger-settings-in-gtm.png"
                alt="Trigger configuration for tracking clicks on email adresses"
                caption={`Trigger configuration for tracking clicks on email adresses.`}
                className="article-img"
              />

            </ul>
          </li>
          <li>
            <H as="h4">Set up the GA event tag</H>
            <ul>
              <li>Create a new tag, select tag type <b>Google Analytics: Universal Analytics</b> and give it the name <em>Event - Email
                link</em>. Then select <b>event</b> as <em>track type</em>.</li>
              <li>The <b>category</b> can be set to a static string <code>Email link click</code>, <b>action</b> is <code>click</code> and <b>label</b> shall reference the <code>{`{{ Click URL }}`}</code>.</li>
              <li>The event <b>value</b> can be <code>0</code> again unless you can assign a meaningful value. If
                you knew for example, that every 10th email contact leads to a 100$ sale, then you could assign 10$ as
                the avg. event value.</li>
              <li>The <b>non-interaction hit</b> should show <code>false</code>, since the interaction is willingly and
                therefore active.</li>
              <li>The <b>Google Analytics Settings</b> variable should be the same as for your page view tag or outbound
                link tag.<br />


                <ImgScreenshot
                  src="gtm-setup/google-analytics-event-tag-emails.png"
                  alt="Configuration GA email click event "
                  caption={`Configuration settings of the GA event tag for emails.`}
                  className="article-img"
                />

              </li>
              <li>The final step is to go down to <em>triggering</em> and <b>select the trigger we just created</b> <em>Event - Mail
                click</em>. Afterwards, <b>save</b> and go to the overview to <b>submit</b> your changes.</li>
            </ul>
          </li>
        </ol>
        <H as="h3">Track clicks on phone numbers</H>
        <p>Tracking taps on phone numbers is primarily helpful on mobile devices. Tapping on a phone number link directly
          initiates a phone call. On desktops, mouse clicks usually don’t initiate anything. But as for tracking clicks on
          emails, it is a good metric to confirm contact rates overall, because it is correlated with other methods of
          contacting a business.</p>
        <p>Learn to configure GTM for tracking phone number clicks, by following the below steps.</p>
        <ol>
          <li>
            <H as="h4">Create a trigger for clicks on telephone numbers</H>
            <ul>
              <li>As with the other guides above, we will need the <b>click URL</b> variable activated from the <em>built-in variables</em>.</li>
              <li>Then create a new trigger <em>Event - Phone click</em> of the type <b>click - just links</b> which only
                triggers on <em>some link clicks</em>.</li>
              <li>Fill the trigger condition as follows: <b>Click URL</b> - <b>contains</b> and then the string <code>tel:</code>. Then hit save.<br />

                <ImgScreenshot
                  src="gtm-setup/phone-number-trigger-in-gtm.png"
                  alt="GTM settings for a phone number trigger"
                  caption={`Settings for a trigger that fires on phone number clicks.`}
                  className="article-img"
                />


              </li>
            </ul>
          </li>
          <li>
            <H as="h4">Create a phone number event tag</H>
            <ul>
              <li>Add yet again a <b>Universal Analytics tag</b> of type <b>event</b> and call it <em>Event - Phone
                clicks</em></li>
              <li>The <b>category</b> is the static string <code>Phone number click</code>, <b>action</b> is <code>click</code> and as a <b>label</b> we use the built-in variable again with double curly
                braces, <code>{`{{ Click URL }}`}</code>.</li>
              <li>If you can’t attach an avg. value to a lead call, keep it on <code>0</code>. Otherwise, add a number for
                the average value.</li>
              <li>Event <b>non-interaction hit</b> should be set to <code>false</code>, because this is an active, willing
                interaction. Select the <b>settings variable</b> from your user-defined variables, which you also use for page views.



                <ImgScreenshot
                  src="gtm-setup/ga-event-phone-number.png"
                  alt="Configuration GA phone number click event"
                  caption={`Configuration of the GA phone number click event.`}
                  className="article-img"
                />
              </li>
              <li>Now connect your newly created trigger with this GA event tag by going down to the triggering area and
                selecting the new trigger <em>Event - Phone click</em>. The last step is to <b>save</b> and click submit.</li>
            </ul>
          </li>
        </ol>
        <H as="h3">Track downloads</H>
        <p>Tracking how often visitors download your materials is a good indicator of engagement. Such downloads can be e.g.
          eBooks, Excel-sheets, images, videos or music.</p><p>Tracking downloads works well to distinguish between visitor groups that were not interested in the page content and visitors that indeed were interested and downloaded what you offered.</p>
        <p>Follow this tutorial to learn setting up download tracking:</p>
        <ol>
          <li>
            <H as="h4">Configure a GTM trigger for download clicks</H>
            <ul>
              <li>Create a new trigger called <em>Event - Downloads</em>, as <em>trigger type</em> choose <b>click -
                just links</b> which only fires on <b>some link clicks</b>.</li>
              <li>For the condition set the built-in variable <b>Click URL</b> to <b>end with</b> the string <code>.pdf</code> to track for example PDF downloads. Alter the extension string to whatever file extension you offer for download.
              </li>
              <li>If you want to track <b>multiple file extensions</b> for download we can use a regular expression. Change the condition to <b>Click URL</b> and then <b>matches RegEx (ignore case)</b> and add this RegEx string <code>.pdf|.mp4|.mp3|.xlsx|.xls|.epub|.jpeg$</code>. Feel free to add any other file extensions that are relevant to your website and remove others that are not. Finish by clicking <b>save</b>.<br />


                <ImgScreenshot
                  src="gtm-setup/gtm-trigger-download-events.png"
                  alt="Trigger configuration for download tracking with a built-in variables"
                  caption={`Trigger configuration for download tracking with built-in variable.`}
                  className="article-img"
                />


              </li>
            </ul>
          </li>
          <li>
            <H as="h4">Track downloads with a Google Analytics event</H>
            <ul>
              <li>Add a new tag of the type Universal Analytics, select <b>event</b> as track-type and name it for example <em>Event - Downloads</em>.
              </li>
              <li>The event <b>category</b> is the string <code>Download</code>, the <b>action</b> is the string <code>click</code> and the <b>label</b> is the <code>{`{{ Click URL }}`}</code>.</li>
              <li>Event <b>non-interaction hit</b> is <code>false</code>, due to the interaction being an active engagement.</li>
              <li>And from the user-defined variables, use the same <b>settings variable</b> as in other tracking tags.<br />

                <ImgScreenshot
                  src="gtm-setup/google-analytics-event-tag-downloads.png"
                  alt="Event tag for download tracking"
                  caption={`Settings of the event tag for download tracking.`}
                  className="article-img"
                />

              </li>

              <li>Now add a trigger to this tag by scrolling down and select <em>Event -
                Downloads</em>.</li>
              <li>Next step, click <b>save</b>, head to the overview and <b>submit</b> the changes.</li>
            </ul>
          </li>
        </ol>
        {/* todo */}
        {/* <H as="h3">Add scroll tracking</H> */}
        <H as="h2">Guide to test tags and triggers</H>
        <p>A google tag manager tutorial wouldn't be complete without a part about debugging. To test any of the previous event configurations and be sure they are working, do any of the following:</p>
        <ol>
          <li><H as="h3">GTM Preview mode</H>First, let's start the preview and debugging mode. In the Google Tag Manager overview, click on the <b>preview</b> button in the top right corner. Then open a new
            tab <b>in the same browser</b> and you will notice that a GTM debugger window pops up on the bottom.<br /><br />
            While in preview mode, execute the tags and triggers yourself. Click e.g. on an external link or a phone number and see if the
            trigger and your tag is shown in the GTM debugger window together with the event details you added.<br />

            <ImgScreenshot
              src="event-tracking/google-tag-manager-debugging-mode.png"
              alt="google tag manager debugger for events"
              className="article-img"
              caption={`The Google Tag Manager debugger opens up automatically, once preview mode is activated and will show data about tags, triggers and variables.`}
            />


          </li>
          <li><H as="h3">Google Analytics realtime report for events</H>Another method is to log in to Google Analytics and then visit the <b>realtime report for events</b>. The events should show up a few seconds after the trigger fired.

            <ImgScreenshot
              src="event-tracking/google-analytics-event-tracking-realtime-report.png"
              alt="google analytics realtime report for events"
              className="article-img"
              caption={`You can verify GTM configurations with the Google Analytics realtime report.`}
            />


          </li>
          <li><H as="h3">Chrome extension for debugging</H>Install a Chrome extension to debug the tag and trigger. I recommend <a rel="noopener" target="_blank"
            href="https://chrome.google.com/webstore/detail/trackie/iphjjodolgbelaogcefgpegebgecopeh">Trackie</a> or <a
              rel="noopener" target="_blank"
              href="https://chrome.google.com/webstore/detail/omnibug/bknpehncffejahipecakbfkomebjmokl">Omnibug</a>, but
            there are other solutions out there as well. Once you added the extension to Chrome, you can open <b>Chrome Developer
              Tools</b> and there will be a new tab available. It shows you all information about the fired tag management
            rules. If you then fire your triggers in debug mode, the extension will show trigger and event
            data.
            <br />
            <ImgScreenshot
              src="gtm-setup/test-gtm-trigger-and-tag-with-trackie.png"
              alt="Trackie Chrome extension shows event data."
              className="article-img"
              caption={`Trackie Chrome Extension shows event data in Chrome Developer Tools.`}
            />

            <ImgScreenshot
              src="gtm-setup/test-gtm-trigger-and-tag-with-omnibug.png"
              alt="Omnibug Chrome Extension shows event data for debugging"
              className="article-img"
              caption={`Omnibug Chrome Extension shows event data for debugging in Chrome Dev Tools.`}
            />
          </li>
        </ol>



        <H as="h2">Download GTM config container file</H>
        <p>Since the above configurations are universally useful to most Google Tag Manager implementations, I created the above
          GTM setup as a file to import into other Google Tag Manager containers.<br />
          It’s a <code>.json</code> file with the settings and naming convention we went through. You can just import the container code without having to set up anything manually.</p>
        <p>Either you <b>use it with a brand new container</b> to save time setting the tags up yourself, or you can <b>import
          them to your existing container</b> and update the Google Analytics settings variable including the tracking ID to your own ID.</p>
        <p>You can download and install these configurations (each with tags, triggers and variables) to set up Google Tag Manager:</p>
        <ul>
          <li>Track outbound links</li>
          <li>Track email clicks</li>
          <li>Track phone number clicks</li>
          <li>Track downloads</li>
        </ul>
        <p className="baseline">Simply import the container settings and deploy them. For demonstration purposes, I added a Google Analytics settings variable with a Google Analytics tracking ID of <em>UA-12345678-9</em>.</p> <p><b>Please update the GA tracking code to your own</b> or
          alternatively, <b>change the GA settings variable in the tag configuration to your own one</b>.</p>
        <p>Download the GTM setup configuration and see below how to import it.</p>
        <a href="/downloads/best-practice-gtm-configs.json" download="best-practice-gtm-configs.json" ><CtaPrimary color="purple">Download GTM setup</CtaPrimary></a>
        <H as="h3">Import settings into your GTM container</H>
        <p>To get the most out of this GTM tutorial, follow the below steps and import the settings to your GTM container:</p>
        <ul>
          <li>go to <b>admin</b> &gt; <b>import container</b>.</li>
          <li>select the JSON file you just downloaded.</li>
          <li>choose a <b>new workspace</b> named e.g. <em>Import Bluerivermountains</em>.</li>
          <li>as <b>import option</b> choose to <b>merge</b> and <b>rename</b> any conflicting tags, triggers and variables.</li>
          <li>click <b>confirm</b> and hit the <b>submit</b> and the <b>publish</b> button to deploy the tags.<br /><br />Let me show you in this video:

            <video

              loading="lazy"
              title={`Tutorial video to import a Google Tag Manager Container`}
              autoplay
              loop
              controls
              css="max-width:100%;border:3px solid lightgrey;"
            >
              <source src="/video/import-gtm-container.mp4" type="video/mp4" />
            </video>

          </li>

          <li>Finally, change the GA tracking ID in the Google Analytics settings variable to your own tracking ID or update the settings variable in the
            tags to your own settings variable.</li>
        </ul>
        <H as="h2">Note on personal data</H>
        <p>We have to be aware the information we track. Data is not just data, because countries have regulations about data privacy which affect the type of information we may collect during tracking.</p>

        <p>Likewise, there are also terms on Google's side, that forbid tracking personal information and send the data to their servers.</p>

        <p>On that note:</p>
        <p>Generally, <b>emails or phone numbers are personally identifiable information (PII)</b> and we are not allowed to
          send that to our Google Analytics account, because it’s <a rel="noopener" target="_blank" href="https://support.google.com/analytics/answer/2795983">against their terms of service</a>.</p>
        <p>However, the phone numbers on our website or our own company email addresses are hardly private, because it is not the users'
          data but our own and publicly available on the website.<br />
          Never the less, if Google Analytics <em>ever</em> checked their database and found that data, they couldn’t know
          that it’s actually not PII data.<br />
          Therefore, I recommend not to take any risk and <b>obfuscate all email addresses and phone numbers sent to
            the Google Analytics account</b>.</p>
        <p>Simo Ahava did some great work and wrote a <a rel="noopener" target="_blank"
          href="https://www.simoahava.com/gtm-tips/remove-pii-google-analytics-hits/">custom task script to remove PII
          from Google Analytics hits</a> and I recommend you implement this along with the above configurations.<br />
          It’s not a must, but by implementing it you avoid any potential confusion as to if you hold PII data or not.</p>
        <H as="h2">FAQ</H>
        <H as="h3">Do I need Google Tag Manager?</H>
        <p>Yes, because your website most likely wants to run Google Analytics and other third-party script tags. Setting all
          that up is a lot easier and faster with Google Tag Manager. Plus your site <a rel="noopener" target="_blank"
            href="https://marketingland.com/audit-of-online-retailer-sites-shows-tag-management-systems-improve-load-times-reduce-errors-62173">loads
            a bit faster</a> too.</p>
        <H as="h3">When should I use Google Tag Manager?</H>
        <p>Already if you only want to run Google Analytics you should use Google Tag Manager. Setting up <Link to="/en/event-tracking">event tracking</Link>, cross-domain tracking or form tracking are common next steps, but a lot <b>faster</b> and <b>easier</b> with GTM than without. There are built-in triggers for scroll tracking and video tracking too, plus many tutorials online explaining how to <Link to="/en/google-analytics-setup">set up Google Analytics</Link> with it.</p>
        <H as="h3">How do I use Google Tag Manager with Google Analytics?</H>
        <p>Log in to <a href="https://analytics.google.com" rel="noopener" target="_blank">analytics.google.com</a> with your Google account and get your Google Analytics tracking code including the tracking ID. Now, don’t add the Google Analytics tag into the source code of your site. The only hard-coded tag should be the Google Tag Manager tag. So head to <a target="_blank" rel="noopener" href="https://tagmanager.google.com/">tagmanager.google.com</a> to get the GTM code snippet and instead implement that one on every page of your website. Finally, implement the GA code through a built-in tag, add your tracking ID and continue to <Link to="/en/google-analytics-setup">set up Google Analytics</Link> <b>through Google Tag Manager</b>.
          For adding marketing tags or configuring custom tags, you always use GTM going forward.</p>
        <H as="h3">What is the difference between Google Analytics and Google Tag Manager?</H>
        <p>Google Analytics is the library to collect data about visits and engagement on your website. Google Tag Manager on the other hand is a library running on your site to implement other libraries or <em>tracking tools</em> like Google Analytics. Because many marketing and analytics tools have extra JavaScript snippets, you use the GTM user interface to set them all up.</p>
        <H as="h3">Where do I put the Google Tag Manager code?</H>
        <p>The <b>first</b> part of the code goes <em>as high as possible</em> into the <code>&lt;head&gt;</code> section. I
          recommend to implement it within the <code>&lt;head&gt;</code> but after any <code>&lt;style&gt;</code> or <code>&lt;script&gt;</code> tags that are vital to render the page - because we don’t want to delay them.<br />
          The <b>second</b> part is to enable a basic functionality in browsers with JavaScript turned
          off. It goes <em>as high as possible</em> into the <code>&lt;body&gt;</code> element.<br />
          The logic behind the positioning of both tags is to ensure the early loading of GTM. It enables you to fire custom tags as early as possible during page load.</p>

        <br />
        <ImgScreenshot
          src="google-analytics-setup/google-tag-manager-code-snippet.png"
          alt="Google Tag Manager code"
          className="article-img"
          caption={`The GTM tag has two parts. The first is placed high into the head tag and the other right after the opening body tag (see below).`}
        />

        <br />

        <ImgScreenshot
          src="data-layer/positionierung-data-layer-gtm.png"
          alt="positioning the datalayer before the tag management tag in the source code"
          className="article-img"
          caption={`The overall order should always be 1. data layer object 2. Google Tag Manager, both in the <head> and 3. the other GTM code high in the <body> tag`}
        />

        <H as="h3">Does Google Tag Manager include Google Analytics?</H>
        <p>No, but Google Tag Manager enables you to implement Google Analytics in seconds with just a few clicks. The only
          thing you need is your <Link to="/en/google-analytics-setup#where-to-get-the-google-analytics-tracking-code">Google Analytics tracking ID</Link>. Generally though, you don’t need to use Google Analytics with Google Tag Manager.
          They are <b>independent</b> of each other.</p>
        <H as="h3">How do I access Google Tag Manager?</H>
        <p>Visit <a target="_blank" href="https://tagmanager.google.com/" rel="noopener">tagmanager.google.com</a> and log in with your Google account to access Google Tag Manager. To start using GTM, create a new account and choose web-property as the target platform. Then take the snippet and install it on each page of your website.</p>
        <Helmet>
          <script type="application/ld+json">{FAQs}</script>
        </Helmet>
      </MainContent>
    </React.Fragment>

  </Layout >
);

export default GTMsetup;
